<template>
  <v-row justify="center">
    <template v-if="shouldShowSelectRouterDialog">
      <v-dialog
        v-model="value"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Select router to backup</span>
          </v-card-title>
          <v-form
            ref="form"
            method="post"
            action="/"
            lazy-validation
            @submit.prevent="backup()"
          >
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="router_id"
                      :items="displayRouters"
                      item-text="displayText"
                      item-value="id"
                      label="Select a router"
                      :rules="[
                        v => !!v || 'Router is required',
                      ]"
                      :loading="isRoutersLoading"
                      outlined
                    ></v-select>
                    <span
                      v-if="!isRoutersLoading && routers.length === 0"
                    >
                      There are no any Routers available. Please go to <router-link to="/routers">Routers</router-link> page and create one.
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDialog()"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="isConfirmBackupDialogButtonLoading"
              >
                Backup
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </template>
    <confirm-dialog
      v-else
      :show-dialog="value"
      :title="'Confirm Backup'"
      :message="'Are you sure you want to backup the router?'"
      :is-agree-button-loading="isConfirmBackupDialogButtonLoading"
      :agree-text="'Backup'"
      @agree="backup()"
      @cancel="closeDialog()"
    />
  </v-row>
</template>

<script>
import axios from 'axios'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    routerId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isRoutersLoading: false,
    isConfirmBackupDialogButtonLoading: false,
    router_id: '',
    routers: [],
  }),
  computed: {
    displayRouters() {
      return this.routers.map(router => ({
        ...router,
        displayText: `${router.station.name} Router`,
      }))
    },
    shouldShowSelectRouterDialog() {
      return this.routerId === null
    },
  },
  created() {
    this.fetchRouters()
  },
  methods: {
    closeDialog() {
      this.$emit('close')

      if (this.router_id) {
        this.$refs.form.resetValidation()
        this.$refs.form.reset()
      }
    },
    backup() {
      if (this.routerId) {
      // Backup logic when routerId is provided
        this.performBackup(this.routerId)
      } else {
      // Backup logic when routerId is not provided
        const isFormValid = this.$refs.form.validate()
        if (isFormValid) {
          this.performBackup(this.router_id)
        }
      }
    },
    performBackup(routerId) {
      this.isConfirmBackupDialogButtonLoading = true
      axios.post(`router-backups/${routerId}`).then(() => {
        this.$emit('backup-created')
        this.$toast.success('Backup created successfully')
      }).catch(error => {
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.$emit('close')
        this.isConfirmBackupDialogButtonLoading = false
        if (!this.shouldShowSelectRouterDialog) {
          this.showConfirmBackupDialog = false
        }
      })
    },
    fetchRouters() {
      this.isRoutersLoading = true
      axios.get('/routers?perPage=all')
        .then(response => {
          this.routers = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isRoutersLoading = false
        })
    },
  },
}
</script>
