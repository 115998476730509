<template>
  <div class="router-backups-table">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isBackupsRefreshing"
                @click="refreshBackups"
              />
            </v-col>
            <v-col class="col-md-6 text-md-right">
              <table-filter
                :table-name="'backups'"
                :show-date-filter="true"
                :filters="tableFilters"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="computedHeaders"
        :items="backups"
        :loading="isBackupsLoading"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        :footer-props="footerProps"
      >
        <template
          v-if="selectedStation === 'all'"
          v-slot:item.router="{ item }"
        >
          {{ item.router.station.name }} router
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                v-bind="attrs"
                small
                outlined
                text
                :loading="downloadingRscFiles[item.id]"
                @click.stop="downloadFile(item.id, 'rsc')"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  left
                  v-on="on"
                >
                  {{ icons.mdiDownload }}
                </v-icon>
                config.rsc
              </v-btn>
            </template>
            <span>Download config.rsc file</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                v-bind="attrs"
                small
                outlined
                text
                :loading="downloadingBackupFiles[item.id]"
                @click.stop="downloadFile(item.id, 'backup')"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  left
                  v-on="on"
                >
                  {{ icons.mdiDownload }}
                </v-icon>
                config.backup
              </v-btn>
            </template>
            <span>Download config.backup file</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                v-bind="attrs"
                small
                outlined
                text
                @click.stop="showDeleteBackupDialog(item.id)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  left
                  v-on="on"
                >
                  {{ icons.delete }}
                </v-icon>
                delete
              </v-btn>
            </template>
            <span>Delete Backup</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <create-router-backup-dialog
      v-model="showConfirmBackupDialog"
      :router-id="routerId"
      @backup-created="refreshBackups()"
      @close="$emit('close-backup-dialog')"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :title="'Confirm Delete'"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteBackup()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {
  mdiDownload, mdiDelete,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import CreateRouterBackupDialog from '@/components/dialogs/CreateRouterBackupDialog.vue'

export default {
  components: {
    RefreshButton,
    TableFilter,
    ConfirmDialog,
    CreateRouterBackupDialog,
  },
  props: {
    showConfirmBackupDialog: {
      type: Boolean,
      default: false,
    },
    backupsUrl: {
      type: String,
      default: null,
    },
    routerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      backups: [],
      isBackupsLoading: true,
      isBackupsRefreshing: false,
      pagination: {
        totalItems: 0,
      },
      defaultDatePickerDate: 'Last 30 days',
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Items per page:',
      },
      tableFilters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      headers: [
        { text: 'Date Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      icons: {
        mdiDownload,
        delete: mdiDelete,
      },
      isRscFileDownloading: false,
      isBackupFileDownloading: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBackupDialogButtonLoading: false,
      showConfirmDeleteDialog: false,
      downloadingRscFiles: {},
      downloadingBackupFiles: {},
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    computedHeaders() {
      const baseHeaders = this.headers

      if (this.selectedStation === 'all') {
        return [{ text: 'Router', value: 'router' }, ...baseHeaders]
      }

      // Return the base headers if selectedStation is not 'all'
      return baseHeaders
    },
    constructCompleteRouterBackupsUrl() {
      const sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      const params = new URLSearchParams({
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDirection: sortOrder,
        fromDate: this.tableFilters.dates.fromDate,
        toDate: this.tableFilters.dates.toDate,
        stationId: this.selectedStation,
      })

      return `${this.backupsUrl}?${params.toString()}`
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchBackups()
      },
      deep: true,
    },
    selectedStation() {
      this.fetchBackups()
    },
  },
  mounted() {
    this.fetchBackups()
  },
  methods: {
    fetchBackups() {
      this.isBackupsLoading = true
      axios.get(this.constructCompleteRouterBackupsUrl)
        .then(response => {
          this.backups = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          console.error('Error fetching router backups:', error)
        })
        .finally(() => {
          this.isBackupsLoading = false
          this.isBackupsRefreshing = false
        })
    },
    refreshBackups() {
      this.isBackupsRefreshing = true
      this.fetchBackups()
    },
    applyFilters(filters) {
      this.tableFilters = { ...this.tableFilters, ...filters }
      this.fetchBackups()
    },
    downloadFile(backupId, fileType) {
      if (fileType === 'rsc') {
        this.$set(this.downloadingRscFiles, backupId, true)
      } else {
        this.$set(this.downloadingBackupFiles, backupId, true)
      }
      const backupsUrl = `router-backups/download/${backupId}/${fileType}`

      axios({
        method: 'GET',
        url: backupsUrl,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)

        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition']

        let filename = `config.${fileType}` // default filename

        if (contentDisposition) {
          const filenameRegex = /filename="?([^"]+)"?\b/
          const matches = contentDisposition.match(filenameRegex)
          if (matches && matches[1]) {
            filename = matches[1]
          }
        }

        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        console.error('There was an error downloading the file:', error)
        this.$toast.error('There was an error downloading the file')
      }).finally(() => {
        if (fileType === 'rsc') {
          this.$set(this.downloadingRscFiles, backupId, false)
        } else {
          this.$set(this.downloadingBackupFiles, backupId, false)
        }
      })
    },
    showDeleteBackupDialog(backupId) {
      this.showConfirmDeleteDialog = true
      this.toDelete = backupId
    },
    async deleteBackup() {
      try {
        this.isConfirmDeleteDialogButtonLoading = true
        await axios.delete(`router-backups/${this.toDelete}`)
        this.$toast.success('Backup deleted successfully')
        this.fetchBackups() // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting backup:', error)
      } finally {
        this.isConfirmDeleteDialogButtonLoading = false
        this.showConfirmDeleteDialog = false
      }
    },
  },
}
</script>
