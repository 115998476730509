var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"router-backups-table"},[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isBackupsRefreshing},on:{"click":_vm.refreshBackups}})],1),_c('v-col',{staticClass:"col-md-6 text-md-right"},[_c('table-filter',{attrs:{"table-name":'backups',"show-date-filter":true,"filters":_vm.tableFilters,"default-date-picker-date":_vm.defaultDatePickerDate},on:{"filter":_vm.applyFilters}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.backups,"loading":_vm.isBackupsLoading,"server-items-length":_vm.pagination.totalItems,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.selectedStation === 'all')?{key:"item.router",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.router.station.name)+" router ")]}}:null,{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":"","outlined":"","text":"","loading":_vm.downloadingRscFiles[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item.id, 'rsc')}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_vm._v(" config.rsc ")],1)]}}],null,true)},[_c('span',[_vm._v("Download config.rsc file")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":"","outlined":"","text":"","loading":_vm.downloadingBackupFiles[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile(item.id, 'backup')}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_vm._v(" config.backup ")],1)]}}],null,true)},[_c('span',[_vm._v("Download config.backup file")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":"","outlined":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteBackupDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({attrs:{"left":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.delete)+" ")]),_vm._v(" delete ")],1)]}}],null,true)},[_c('span',[_vm._v("Delete Backup")])])]}}],null,true)}),_c('v-divider')],1),_c('create-router-backup-dialog',{attrs:{"router-id":_vm.routerId},on:{"backup-created":function($event){return _vm.refreshBackups()},"close":function($event){return _vm.$emit('close-backup-dialog')}},model:{value:(_vm.showConfirmBackupDialog),callback:function ($$v) {_vm.showConfirmBackupDialog=$$v},expression:"showConfirmBackupDialog"}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"title":'Confirm Delete',"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"agree-text":'Delete'},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deleteBackup()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }